import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject, HostListener } from '@angular/core';
import { isPlatformServer, isPlatformBrowser, ViewportScroller, DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Event } from '../models/event';
import { environment } from '../../environments/environment';

import { SeoService } from '../services/seo.service';
import { ScrollService } from '../services/scroll.service';
import { EventListService } from '../services/event-list.service';
import { TransferState, makeStateKey, Title } from '@angular/platform-browser';
import { AuthService } from '../services/auth.service';
import { UtilsService } from '../services/utils.service';
import { ActivatedRoute } from '@angular/router';
import { Category } from '../models/category';
import { CategoryService } from '../services/category.service';

const EVENT_LIST = makeStateKey<Event[]>('eventList');

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit, OnDestroy {

  eventList: Event[];
  environment: any;
  isLoading: boolean = true;
  loadMoreIsLoading: boolean = false;
  moveToPreviousScrollPosition: boolean = true;
  sortByDate: boolean = environment.ui.showOnFilter.sortBy === 'date';
  alternativeURL: string;

  //Search bar
  allCategories: Category[] = [];
  isFilterActive = false;
  isMobile: boolean = false;

  constructor(
    private seo: SeoService,
    private scrollService: ScrollService,
    private viewportScroller: ViewportScroller,
    public eventListService: EventListService,
    public authService: AuthService,
    private state: TransferState,
    private titleService: Title,
    public translate: TranslateService,
    private categoryService: CategoryService,
    @Inject(PLATFORM_ID) private platformId: any,
    private route: ActivatedRoute,
    public utilsService: UtilsService) {
    const params = this.route.snapshot.params;
    this.sortByDate = params.sortBy === 'date' || (!params.sortBy && environment.ui.showOnFilter.sortBy === 'date');
    const paramsURL = this.sortByDate ? { ...params, sortBy: 'popularity'} : { ...params, sortBy: 'date'};
    this.alternativeURL = '/filter;' + Object.keys(paramsURL).map((key: string) => key + '=' + paramsURL[key]).join(";");
    this.environment = environment;
  }

  ngOnInit() {
    //Check if is mobile
    this.checkScreenSize();
    //Setup categories
    this.setupCategories();
    //Setup SEO information
    this.setupSEO();
    //Get notified when the event list changes
    this.initListSubscription();
    //Get notified when the loading status changes
    this.initLoadingSubscription();
    //Get notified when the load more events loading status changes
    this.initLoadMoreIsLoadingSubscription();
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollService.setLastPositionForEventList(this.viewportScroller.getScrollPosition()[1]);
    }
  }

  setupCategories() {
    this.allCategories = this.categoryService.getAllCategories();
  }

  onFilterStateChange(state: {focused: boolean, hasValue: boolean}) {
    this.isFilterActive = state.focused || state.hasValue;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenSize();
  }

  private checkScreenSize() {
    this.isMobile = window.innerWidth < 768; // Adjust breakpoint as needed
  }

  lastEventIsRendered() {
    if (isPlatformBrowser(this.platformId)) {
      if (typeof window !== 'undefined'
        && this.scrollService.getPreviousUrl()?.indexOf('/event/') != -1
        && this.moveToPreviousScrollPosition) {
        window.scrollTo({ left: 0, top: this.scrollService.getLastPositionForEventList(), behavior: 'smooth' });
        this.moveToPreviousScrollPosition = false;
      }
    }
  }
  
  setupSEO() {
    //Tags and JSON+LD
    this.seo.generateTags({});
    //Set the title of the page
    this.titleService.setTitle(this.utilsService.getTitleFrontpage() + ' | ' + environment.content.siteName);
  }

  initListSubscription() {
    this.eventListService.listChanges().subscribe(
      list => {
        this.eventList = list;
        if (isPlatformServer(this.platformId)) {
          this.state.set<Event[]>(EVENT_LIST, this.eventList);
        }
        if (this.eventList == null) {
          this.eventList = this.state.get<Event[]>(EVENT_LIST, []);
        }
      }
    );
  }

  initLoadingSubscription() {
    this.eventListService.listIsLoading().subscribe(
      listIsLoading => this.isLoading = listIsLoading
    );
  }

  initLoadMoreIsLoadingSubscription() {
    this.eventListService.loadMoreIsLoading().subscribe(
      loadMoreIsLoading => this.loadMoreIsLoading = loadMoreIsLoading
    );
  }

  moreEventsAvailable() {
    return this.eventListService.moreEventsAvailable();
  }

  loadMoreEvents() {
    return this.eventListService.loadMoreEvents();
  }
}
