import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouterParamService {
  params$: Observable<{ [key: string]: string }>;

  constructor(
    private readonly router: Router,
    private readonly rootRoute: ActivatedRoute,
    private readonly location: Location
  ) {
    this.params$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.getParams(this.rootRoute))
    )
  }

  paramsSnapshot() {
    return this.getParams(this.rootRoute)
  }

  /**
   * Updates URL without causing a full navigation event
   * @param path URL path to update to
   */
  updateUrlWithoutNavigation(path: string) {
    this.location.go(path);
  }

  /**
   * Builds a matrix parameter URL from an object
   * @param basePath The base path (e.g., '/filter')
   * @param params Object containing the parameters
   * @returns Formatted URL string
   */
  buildMatrixParamUrl(basePath: string, params: { [key: string]: any }): string {
    if (!params || Object.keys(params).length === 0) {
      return basePath;
    }
    
    const paramString = Object.entries(params)
      .filter(([_, value]) => value !== null && value !== undefined && value !== '')
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join(';');
    
    return paramString.length > 0 ? `${basePath};${paramString}` : basePath;
  }

  private getParams(route: ActivatedRoute) {
    // route param names (eg /a/:personId) must be ditinct within
    // a route otherwise they'll be overwritten
    let params = route.snapshot.params
    params = { ...route.snapshot.queryParams, ...params };
    if (route.children) {
      for (let r of route.children) {
        params = { ...this.getParams(r), ...params };
      }
    }
    if (route.snapshot.data?.type === 'navigational') {
      params = { isNavigational: true, ...params};
    }
    return params;
  }
}