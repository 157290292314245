import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Event } from '../models/event';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../services/utils.service';
import { environment } from '../../environments/environment';
import moment from 'moment';
import { CategoryService } from '../services/category.service';
import { MatDialog } from '@angular/material/dialog';
import { Price } from '../models/price';
import { TicketTypeService } from '../services/ticket-type.service';
import { first, debounceTime } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';

const ics = require('ics');


@Component({
  selector: 'app-meta-link',
  templateUrl: './meta-link.component.html',
  styleUrls: ['./meta-link.component.scss']
})
export class MetaLinkComponent implements OnInit, AfterViewInit {

  @Input()
  property: string;

  @Input()
  event: Event;

  @Input()
  superEvent: Event;

  title: string;
  showProperty: boolean;
  iconSrc: string;
  body: any = [];
  mapImage: string;
  environment: any;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private categoryService: CategoryService,
    private ticketTypeService: TicketTypeService,
    public dialog: MatDialog,
    private utilsService: UtilsService) {
    this.environment = environment;
  }
  ngAfterViewInit(): void {
    this.route.fragment.pipe(
      debounceTime(500),
      first(),
    ).subscribe((fragment: string) => this.scrollToAnchor(fragment));
  }

  scrollToAnchor(fragment: string) {
    this.viewportScroller.scrollToAnchor(fragment);
  }

  ngOnInit(): void {
    this.setupProperty();
    //Subscribe to the language change event
    this.translate.onLangChange.subscribe(() => {
      //If the language change while we are on this screen
      this.setupProperty();
    });
  }

  setupProperty(): void {
    this.body = [];

    if (this.property == 'categories') {
      this.title = this.translate.instant("Categories");
      this.showProperty = true;
      this.iconSrc = 'assets/icons/category.svg';
      this.body = [{
        name: '',
        callToActions: this.event.categories.map((categoryID) => {
          return {
            label: this.categoryService.getCategoryName(categoryID),
            link: '/category/' + categoryID,
            isExternal: false
          };
        })
      }];
    }

    if (this.property == 'add-to-my-calendar') {
      this.title = this.translate.instant("Save in my calendar");
      this.showProperty = true;
      this.iconSrc = 'assets/icons/save.svg';
    }

    if (this.property == 'share') {
      this.title = this.translate.instant("Share");
      this.showProperty = true;
      this.iconSrc = 'assets/icons/share.svg';
    }

    if (this.property == 'tickets') {
      this.title = this.translate.instant("Tickets");
      this.showProperty = !this.event.eventSoldOut && !this.event.eventCancelled && this.event.ticketsInformation != 'noTicketsInfo';
      this.iconSrc = 'assets/icons/ticket.svg';
      if (this.showProperty) {
        if (this.event.ticketsInformation == 'free' || this.allPricesAreGratis()) {
          this.body = [{
            name: this.translate.instant("Free"),
            callToActions: []
          }];
        } else {
          this.body = (this.event.prices?.length > 0 && this.event.prices.map((price: Price) => ({
            name: this.ticketTypeService.getTicketTypeName(price),
            callToActions: [{
              label: price.price == 0 ? this.translate.instant("Free") : 'kr ' + price.price + ',-',
              link: null,
              isExternal: true
            }]
          })))
        }
      }
      if (this.event.ticketsURL) {
        this.body.push({
          callToActions: [{
            label: this.event.registrationEnabled ? this.translate.instant("Book tickets") : (this.allPricesAreGratis() ? this.translate.instant("Confirm attendance") : this.translate.instant("Buy tickets")),
            link: this.event.registrationEnabled ? ['/event/', this.event.event_slug] : this.event.ticketsURL,
            fragment: (this.event.registrationEnabled && 'book-tickets') || '',
            isExternal: !this.event.registrationEnabled
          }]
        })
      }
    }

    if (this.property == 'facebookURL') {
      this.title = this.translate.instant("Event in Facebook");
      this.showProperty = this.event.facebookURL ? true : false;
      this.iconSrc = 'assets/icons/facebook-link.svg';
      if (this.showProperty) {
        this.body = [{
          name: '',
          callToActions: [{
            label: this.translate.instant("See event"),
            link: this.event.facebookURL,
            isExternal: true
          }]
        }];
      }
    }

    if (this.property == 'streamingURL') {
      this.title = "Streaming";
      this.showProperty = this.event.streamingURL ? true : false;
      this.iconSrc = 'assets/icons/streaming.svg';
      if (this.showProperty) {
        this.body = [{
          name: '',
          callToActions: [{
            label: this.translate.instant("Watch live stream"),
            link: this.event.streamingURL,
            isExternal: true
          }]
        }];
      }
    }

    if (this.property == 'moreInfoURL') {
      this.title = this.translate.instant("More info");
      this.showProperty = this.event.moreInfoURL ? true : false;
      this.iconSrc = 'assets/icons/more-info.svg';
      if (this.showProperty) {
        this.body = [{
          name: '',
          callToActions: [{
            label: this.translate.instant("Visit") + " " + this.getDomainName(this.event.moreInfoURL),
            link: this.event.moreInfoURL,
            isExternal: true
          }]
        }];
      }
    }

    if (this.property == 'videosURL') {
      this.title = this.translate.instant("Videos");
      this.showProperty = this.event.videosURL ? true : false;
      this.iconSrc = 'assets/icons/video.svg';
      if (this.showProperty) {
        this.body = [{
          name: '',
          callToActions: [{
            label: this.translate.instant("Watch videos"),
            link: this.event.videosURL,
            isExternal: true
          }]
        }];
      }
    }

    if (this.property == 'organizers') {
      this.title = this.translate.instant("Organizers");
      this.showProperty = this.event.organizers?.length > 0 ? true : false;
      this.iconSrc = 'assets/icons/organizer.svg';
      if (this.showProperty) {
        this.body = this.event.organizers.map(org => {
          let defaultBody = {
            name: org.name,
            callToActions: []
          };
          if (org.website) {
            defaultBody.callToActions.push({
              label: this.translate.instant("Visit organizer website"),
              link: org.website,
              isExternal: true
            })
          }
          if (org.telephoneNumber) {
            defaultBody.callToActions.push({
              label: org.telephoneNumber,
              link: 'tel:' + org.telephoneNumber,
              isExternal: true
            })
          }
          if (org.email) {
            defaultBody.callToActions.push({
              label: org.email,
              link: 'mailto:' + org.email,
              isExternal: true
            })
          }
          return defaultBody;
        });
      }
    }

    if (this.property == 'superEvent') {
      this.title = this.translate.instant("Part of") + " " + this.utilsService.getTitle(this.superEvent);
      this.showProperty = this.superEvent && this.event.type == 'has-super';
      this.iconSrc = 'assets/icons/list.svg';
      if (this.showProperty) {
        this.body = [{
          name: '',
          callToActions: [{
            label: this.translate.instant("See more events from") + " " + this.utilsService.getTitle(this.superEvent),
            link: '/event/' + this.superEvent.event_slug,
            isExternal: false
          }]
        }];
      }
    }

    if (this.property == 'ageRestriction') {
      this.title = this.translate.instant("Age restrictions");
      this.showProperty = this.event.ageRestriction != 'no-restriction';
      this.iconSrc = 'assets/icons/info.svg';
      if (this.showProperty) {
        if (this.event.ageRestriction == 'minimum-age' && this.event.minimumAge) {
          this.body.push({
            name: this.translate.instant("From") + ' ' + this.event.minimumAge + ' ' + this.translate.instant("years"),
            callToActions: []
          });
        }
        if (this.event.ageRestriction == 'maximum-age' && this.event.maximumAge) {
          this.body.push({
            name: this.translate.instant("Until") + ' ' + this.event.maximumAge + ' ' + this.translate.instant("years"),
            callToActions: []
          });
        }
      }
    }

    if (this.property == 'venue' && this.event.mode == 'offline') {
      this.title = this.translate.instant("Venue");
      this.showProperty = this.event.venue?.name || false;
      this.iconSrc = 'assets/icons/map-marker.svg';
      if (this.showProperty) {
        let paragraph = {
          name: this.event.venue.name,
          note: this.event.venueNote,
          address: this.venueAddress(this.event.venue.address),
          municipality: this.venueLocation(this.event.venue.address),
          callToActions: [{
            label: this.translate.instant("Open in Google Maps"),
            link: 'https://www.google.com/maps/search/?api=1&query=' + this.event.venue.location.latitude + '%2C' + this.event.venue.location.longitude,
            isExternal: true
          }]
        };
        this.mapImage = 'https://maps.googleapis.com/maps/api/staticmap?center=' + this.event.venue.location.latitude + ',' + this.event.venue.location.longitude + '&scale=2&markers=' + this.event.venue.location.latitude + ',' + this.event.venue.location.longitude + '&zoom=16&size=215x215&key=' + environment.firebase.apiKey;
        this.body.push(paragraph);
      }
    }

    if (this.property == 'mode') {
      this.title = this.translate.instant("Event mode");
      this.showProperty = this.event.mode == 'online';
      this.iconSrc = 'assets/icons/world.svg';
      if (this.showProperty) {
        this.body.push({
          name: this.translate.instant("Online event"),
          callToActions: []
        });
      }
    }
  }
  getDomainName(url: string) {
    var matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    return (matches && matches[1]) || this.translate.instant('website');
  }

  allPricesAreGratis(): boolean {
    // Check if prices exist and is not empty
    if (!this.event.prices || this.event.prices.length === 0) {
      return false; // Or return true depending on your business logic
    }
    
    // Apply reduce with an initial value of 0
    return (this.event.prices.map((price) => price.price).reduce((prev, curr) => prev + curr, 0) === 0);
  }

  venueAddress(venueAddress: string): string {
    return (venueAddress && venueAddress.replace(", Norway", "").replace(", Norge", "").split(",")[0]) || '';
  }

  venueLocation(venueAddress: string): string {
    return (venueAddress && venueAddress.replace(", Norway", "").replace(", Norge", "").split(",")[1]) || '';
  }

  //Returns a link to prefill the events fill on Google Calendar
  getLinkForGoogleCalendar() {
    const location: string = this.event.mode !== 'online' && this.event.venue?.address ? '&location=' + this.event.venue?.address : '';
    const dates: string = moment(this.event.startDate.seconds, "X").format("YYYYMMDD[T]HHmmss") + "/" + moment(this.event.endDate.seconds, "X").format("YYYYMMDD[T]HHmmss");
    return `http://www.google.com/calendar/event?action=TEMPLATE&dates=${dates}&text=${this.event.title_nb}&details=${this.event.desc_nb}${location}`;
  }

  //Create an ICS file and starts a downloading
  generateICS() {
    const startArray: any = moment(this.event.startDate.seconds, "X").format('YYYY-M-D-H-m').split("-");
    const latLon: any = this.event.mode !== 'online' && this.event.venue?.location ? { lat: this.event.venue.location.latitude, lon: this.event.venue.location.longitude } : null;
    const organizer: any = this.event.organizers[0].email ? { name: this.event.organizers[0].name, email: this.event.organizers[0].email } : null;
    const icsEvent: any = {
      start: startArray,
      duration: { hours: Math.floor(this.event.duration / 60), minutes: this.event.duration % 60 },
      title: this.event.title_nb,
      description: this.event.desc_nb,
      location: this.event.venue?.name || '',
      url: environment.siteURL + '/event/' + this.event.event_slug,
      categories: this.event.categories,
    };
    if (latLon) {
      icsEvent.geo = latLon;
    }
    if (organizer) {
      icsEvent.organizer = organizer;
    }
    ics.createEvent(icsEvent, (error, value) => {
      if (error) {
        console.error(error)
      } else {
        this.downloadFile(value, icsEvent.title);
      }
    })
  }

  //Prompt to download a file with the given data and filename
  downloadFile(data: any, filename: string) {
    if (typeof window !== 'undefined') {
      var a = document.createElement("a");
      document.body.appendChild(a);
      const blob = new Blob([data], { type: 'text/calendar' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename + '.ics';
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

}
