<div class="wrapper">
  <div class="mat-body privacy-policy mat-elevation-z1 page">
    <div *ngIf="translate.currentLang == 'nb'" fxLayout="column">
      <h1 class="mat-h1">Kriterier og tips for arrangementer</h1>
      <p>La oss sammen sørge for at ingen går glipp av de beste opplevelsene i byen vår!</p>
      
      <p>For at kalenderen skal være relevant og en verdifull ressurs for byens innbyggere og besøkende, har vi lagt følgende kriterier til grunn:</p>
      
      <ol>
        <li><strong>Relevans:</strong> Arrangementet bør være relevant for byens innbyggere og besøkende. Det kan være kulturelle, sosiale, sportslige eller kommersielle arrangementer som appellerer til et bredt publikum.</li>
        <li><strong>Offentlig tilgjengelighet:</strong> Arrangementet bør være åpent for allmennheten, enten gratis eller mot betaling. Private arrangementer som ikke er tilgjengelige for offentligheten skal ikke inkluderes.</li>
        <li><strong>Dato og tid:</strong> Sørg for at dato og tid for arrangementet er klart spesifisert. Dette hjelper folk med å planlegge og delta. Gjentagende tilstelninger som fotballtreninger, korøvelser etc. skal ikke legges inn i denne kalenderen.</li>
        <li><strong>Lokasjon:</strong> Inkluder nøyaktig adresse og eventuelle spesifikke instruksjoner for å finne frem til arrangementet. Dette gjør det enklere for folk å delta.</li>
        <li><strong>Beskrivelse:</strong> En kort, men informativ beskrivelse av arrangementet, inkludert hva det handler om, hvem som arrangerer det, og hva deltakerne kan forvente.</li>
        <li><strong>Lenke/kontaktinformasjon:</strong> Inkluder en lenke til ditt arrangement om det er en billettside, fb-event eller hjemmeside. Kontaktinformasjon for arrangøren er også nyttig slik at folk kan stille spørsmål eller få mer informasjon om arrangementet.</li>
        <li><strong>Bærekraft og sikkerhet:</strong> Arrangementet bør oppfylle eventuelle lokale krav til sikkerhet og bærekraft. Dette kan inkludere tillatelser, sikkerhetsplaner og miljøhensyn.</li>
        <li><strong>Målgruppe:</strong> Angi hvilken målgruppe arrangementet er rettet mot, for eksempel familier, ungdom, voksne eller spesifikke interessegrupper.</li>
        <li><strong>Tilgjengelighet:</strong> Sørg for at arrangementet er tilgjengelig for personer med funksjonsnedsettelser, inkludert informasjon om tilgjengelige fasiliteter og tjenester.</li>
      </ol>

      <p>Lykke til med ditt arrangement!</p>
      
      <p>PS! Admin har rettighet til å slette arrangementer som er støtende, diskriminerende eller ikke følger kriteriene over.</p>
    </div>
    
    <div *ngIf="translate.currentLang != 'nb'" fxLayout="column">
      <h1 class="mat-h1">Criteria and tips for events</h1>
      <p>Let's ensure together that no one misses out on the best experiences in our city!</p>
      
      <p>For the calendar to be relevant and a valuable resource for the city's residents and visitors, we have established the following criteria:</p>
      
      <ol>
        <li><strong>Relevance:</strong> The event should be relevant to the city's residents and visitors. It can be cultural, social, sporting or commercial events that appeal to a wide audience.</li>
        <li><strong>Public accessibility:</strong> The event should be open to the public, either free or for a fee. Private events that are not accessible to the public should not be included.</li>
        <li><strong>Date and time:</strong> Make sure that the date and time for the event are clearly specified. This helps people plan and participate. Recurring activities such as football practices, choir rehearsals, etc. should not be entered into this calendar.</li>
        <li><strong>Location:</strong> Include the exact address and any specific instructions for finding the event. This makes it easier for people to attend.</li>
        <li><strong>Description:</strong> A short but informative description of the event, including what it's about, who is organizing it, and what participants can expect.</li>
        <li><strong>Link/contact information:</strong> Include a link to your event if there is a ticket page, Facebook event, or website. Contact information for the organizer is also useful so people can ask questions or get more information about the event.</li>
        <li><strong>Sustainability and safety:</strong> The event should meet any local requirements for safety and sustainability. This may include permits, safety plans, and environmental considerations.</li>
        <li><strong>Target audience:</strong> Specify which target audience the event is aimed at, for example families, youth, adults, or specific interest groups.</li>
        <li><strong>Accessibility:</strong> Ensure that the event is accessible to people with disabilities, including information about accessible facilities and services.</li>
      </ol>

      <p>Good luck with your event!</p>
      
      <p>PS! Admin has the right to delete events that are offensive, discriminatory, or do not follow the criteria above.</p>
    </div>
  </div>
</div>
