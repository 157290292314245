import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { Filter } from '../models/filter';
import { Category } from '../models/category';

import { CategoryService } from '../services/category.service';
import { FormControl, FormGroup } from '@angular/forms';
import { EventListService } from '../services/event-list.service';

import { environment } from '../../environments/environment';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { RouterParamService } from '../services/router-param.service';

@Component({
  selector: 'app-smart-filter',
  templateUrl: './smart-filter.component.html',
  styleUrls: ['./smart-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() filterStateChange = new EventEmitter<{ focused: boolean, hasValue: boolean }>();

  defaultFilter: Filter = new Filter();
  filter: Filter = new Filter();
  filterForm = new FormGroup({
    categories: new FormControl()
  });

  //RegEx
  catsRegExp: RegExp = new RegExp(/categories:([A-Z]|-|,|\d)*/gi);
  extraSpacesRegExp: RegExp = new RegExp(/ +/gi);


  //View logic
  omniFocused: boolean = false;

  catsSubs: Subscription;

  //Fields data
  allCategories: Category[];


  //Controls
  omni: FormControl = new FormControl();

  //Subscriptions
  filterURLChange: Subscription;
  omniSubs: Subscription;


  environment: any;

  constructor(
    private translate: TranslateService,
    private eventListService: EventListService,
    public categoryService: CategoryService,
    private routerParam: RouterParamService,
    private adapter: DateAdapter<any>) {
    this.environment = environment;
    // For the datepicker component
    this.setAdapterLocale(this.translate.currentLang);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    //Init field listeners
    this.setupListeners();
    //Parse parameters from URL and set them to the omni
    this.maybeSetFiltersFromURL();
    // Force an initial value change to trigger the filtering process
    setTimeout(() => {
      // Use the current value (or empty string) to trigger the valueChanges event
      const currentValue = this.omni.value || '';
      this.omni.setValue(currentValue, { emitEvent: true });
    });
  }

  ngOnDestroy() {
    this.filterURLChange?.unsubscribe();
    this.omniSubs?.unsubscribe();
    this.catsSubs?.unsubscribe();
  }

  setAdapterLocale(language: string) {
    if (language != 'nb') {
      this.adapter.setLocale('en-GB');
    } else {
      this.adapter.setLocale(language);
    }
  }

  //LISTENERS
  setupListeners() {
    this.setupURLChangeListener();
    this.setupOmniListener();
    this.setupCategoriesListener();
  }

  setupURLChangeListener() {
    this.filterURLChange = this.routerParam.params$.subscribe(
      (params) => {
        if (params?.isNavigational) {
          this.parseParametersForFilters(params);
          this.applyFiltersToFields();
          this.eventListService.filterEvents(this.filter);
        }
      });
  }

  setupOmniListener(): void {
    this.omniSubs = this.omni.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((newValue) => this.onOmniChanges(newValue));
  }

  setupCategoriesListener() {
    this.catsSubs = this.filterForm.controls.categories.valueChanges.subscribe(
      (newCats: string[]) => {
        let currentOmni = this.omni.value || '';
        if (newCats?.length > 0) {
          if (currentOmni?.indexOf("categories:") != -1) {
            currentOmni = currentOmni.replace(this.catsRegExp, "categories:" + newCats.join(","));
          } else {
            currentOmni += " categories:" + newCats.join(",");
          }
        } else {
          currentOmni = currentOmni.replace(this.catsRegExp, '').trim();
        }
        this.omni.setValue(currentOmni);
      }
    )
  }

  //Parse the omni value to build a new filter
  onOmniChanges(newValue: string) {
    this.filter = new Filter();
    this.emitState();
    if (newValue) {
      let omniStr = newValue;

      //Parse categories
      const catArr = omniStr.match(this.catsRegExp);
      if (catArr?.length > 0) {
        this.filter.categories = catArr[0].split(":")[1].split(",");
        omniStr = omniStr.replace(catArr[0], '');
      }

      this.filter.searchTerm = omniStr?.replace(this.extraSpacesRegExp, ' ').trim() || null;

      if (!this.filter.isEqual(this.defaultFilter)) {
        // Use the enhanced RouterParamService to update URL without navigation
        const simplifiedFilter = this.simplifyCurrentFilter();
        const path = this.routerParam.buildMatrixParamUrl('/filter', simplifiedFilter);
        this.routerParam.updateUrlWithoutNavigation(path);
      }
    } else {
      this.routerParam.updateUrlWithoutNavigation('/');
    }

    this.eventListService.filterEvents(this.filter);
  }

  onOmniFocused() {
    this.omniFocused = true;
    this.emitState();
  }

  onOmniBlur() {
    this.omniFocused = false;
    this.emitState();
  }


  private emitState() {
    const hasValue = !!this.omni.value && this.omni.value.trim().length > 0;
    this.filterStateChange.emit({
      focused: this.omniFocused,
      hasValue: hasValue
    });
  }

  clear(): void {
    this.resetFilters();
  }

  //Parse the filter values from URL
  maybeSetFiltersFromURL(): void {
    // Get parameters immediately from the snapshot
    const params = this.routerParam.paramsSnapshot();

    if (Object.keys(params).length > 0) {
      this.parseParametersForFilters(params);
      this.applyFiltersToFields();
      this.eventListService.filterEvents(this.filter);
    }
  }

  parseParametersForFilters(params): void {
    this.filter.searchTerm = this.parseSearchTerm(params['searchTerm']);
    this.filter.categories = this.parseCategories(params['categories']);
  }

  parseCategories(param: string): string[] | null {
    let catIDs = [];
    if (param && param != "null") {
      const catObjs = param.split(",").map(id => this.categoryService.getCategory(id.toUpperCase()));
      catIDs = catObjs.map(catObj => (catObj && catObj.id) || null).filter(catID => catID != null);
      return catIDs;
    }
    return this.defaultFilter.categories;
  }

  parseSearchTerm(param: string): string | null {
    if (param != '' && param != "null") {
      return param;
    }
    return this.defaultFilter.searchTerm;
  }

  applyFiltersToFields(): void {
    this.omni.setValue(this.filter.searchTerm);
    this.filterForm.controls.categories.setValue(this.filter.categories);
  }

  //Reset Filter
  resetFilters() {    
    this.filterForm.controls.categories.setValue('');
    this.omni.setValue('');
    this.routerParam.updateUrlWithoutNavigation('/');
  }

  isDefaultFilter(): boolean {
    return this.filter.isEqual(this.defaultFilter);
  }

  simplifyCurrentFilter(): Filter {
    let simplifiedFilter = this.filter.clone();
    for (let filterProperty of Object.keys(simplifiedFilter)) {
      if (simplifiedFilter[filterProperty] == this.defaultFilter[filterProperty] || simplifiedFilter[filterProperty].length == 0) {
        delete simplifiedFilter[filterProperty];
      }
    }
    return simplifiedFilter;
  }

}
