import { Component, OnInit } from '@angular/core';
import { SeoService } from '../services/seo.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-tips-for-organizers',
  templateUrl: './tips-for-organizers.component.html',
  styleUrls: ['./tips-for-organizers.component.scss']
})
export class TipsForOrganizersComponent implements OnInit {

  environment: any;

  constructor(private seoService: SeoService,
    public translate: TranslateService) {
    this.environment = environment;
  }

  ngOnInit(): void {
    this.seoService.generateTags({ title: this.translate.instant("Kriterier og tips for arrangementer") });
  }
}
